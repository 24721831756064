import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";

export function Example() {
  const [count, setCount] = React.useState(0);

  let segments = [];
  for (let i = 0; i < count; i++) {
    segments.push(
      <motion.div
        style={{
          width: 50,
          height: 15,
          borderRadius: 5,
          backgroundColor:
            i > 5 ? (i > 7 ? "OrangeRed" : "Orange") : "LimeGreen",
          originY: 1,
          marginTop: i < 8 ? 10 : 0
        }}
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        key={i} // Needed for AnimatePresence
      />
    );
  }

  return (
    <motion.div
      style={{
        width: 130,
        height: 236,
        borderRadius: 10,
        backgroundColor: "#fff",
        position: "relative"
      }}
    >
      <motion.div
        style={{
          width: 50,
          height: 108,
          borderRadius: "5px 5px 0 0",
          backgroundColor: "#06f",
          position: "absolute",
          top: 10,
          left: 10,
          color: "#fff",
          fontSize: 32,
          fontWeight: 600,
          textAlign: "center",
          lineHeight: "108px",
          cursor: "pointer"
        }}
        whileTap={{ opacity: 0.6 }}
        onTap={() => {
          if (count < 9) {
            setCount(count + 1);
          }
        }}
      >
        +
      </motion.div>
      <motion.div
        style={{
          width: 50,
          height: 107,
          borderRadius: "0 0 5px 5px",
          backgroundColor: "#06f",
          position: "absolute",
          bottom: 10,
          left: 10,
          color: "#fff",
          fontSize: 32,
          fontWeight: 600,
          textAlign: "center",
          lineHeight: "109px",
          cursor: "pointer"
        }}
        whileTap={{ opacity: 0.6 }}
        onTap={() => {
          if (count > 0) {
            setCount(count - 1);
          } else {
            setCount(0);
          }
        }}
      >
        -
      </motion.div>
      <div
        style={{
          width: 50,
          height: 215,
          position: "absolute",
          right: 10,
          top: 10,
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-start"
        }}
      >
        <AnimatePresence>{segments}</AnimatePresence>
      </div>
    </motion.div>
  );
}
